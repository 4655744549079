<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <!-- <header-component /> -->
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Italian > English Literary Translation
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                When you hire me to translate your novel, memoir, or short story to 
                English, you’ll be getting the benefit not only of my extensive 
                experience (20+ years) as a translator, but also my editorial expertise. 
                There are quite a few Italian>English translators for hire, but not 
                many of them are also editors and published authors in their own right.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Recent and Current Literary Translation Projects
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <ul class="space-y-4">
                <li><span class="italic">Let Me Fly</span> (from Italian): Book One of a new paranormal reverse harem romance series (as yet unnamed) by bestselling author Angela Camilla Pontone. Spring/summer 2021. Available soon on Amazon.com.</li>
                <li><span class="italic">The Fall of the Angel</span> (from Italian): A prequel by Elisa S. Amore, bestselling Italian author in both Italian and English. Spring 2021.</li>
                <li><span class="italic">Oops, Someone's in Trouble</span> (from Italian): Book One of the three-book contemporary romance series, Accidentally in Love, by bestselling author Angela Camilla Pontone. Winter/Spring 2020. Available on Amazon.com.</li>
                <li><span class="italic">Rogue Arena</span> (from Italian): Book Two of a new three-book paranormal romance series by Elisa S. Amore, bestselling Italian author. Winter 2020, Spring 2021. Available soon on Amazon.com.</li>
                <li><span class="italic">Flames of Rage</span> (from Italian): Book Three of the three-book paranormal reverse harem romance series, The Lost Fae, by bestselling author Angela Camilla Pontone. Winter 2019. Available on Amazon.com.</li>
                <li><span class="italic">Flames of Truth</span> (from Italian): Book One of the three-book paranormal reverse harem romance series, The Lost Fae, by bestselling author Angela Camilla Pontone. Fall 2018/Winter 2019. Available on Amazon.com.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
// import HeaderComponent from "@/components/HeaderSmall.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    // HeaderComponent,
    FooterComponent,
  },
};
</script>
