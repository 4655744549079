<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Español
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                Cuando Ud. me contrate para traducir su novela, sus memorias, o sus cuentos al 
                inglés, aprovechará no sólo mis más de veinte años de experiencia en traducción, 
                sino también mi pericia como editora y correctora de estilo. Además, soy autora 
                de tres novelas y un libro de memorias (publicados en Amazon), por lo cual ¡tengo 
                mucha empatía con los problemas de los autores! : )
              </p>
              <p>
                Tengo una maestría en literatura iberoamericana y otra en estudios latinoamericanos, 
                y soy lectora ávida en inglés, español, e italiano. Además, aunque nací en EEUU, 
                viví por veinte años en México, donde hice traducciones y enseñé en varias universidades. 
                También he enseñado en varias universidades en Estados Unidos y trabajado como intérprete 
                certificada en los tribunales. En los últimos años, he traducido varias novelas del 
                italiano también.
              </p>
            </div>
          </div>

          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Traducciones literarias recientes
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <ul class="space-y-4">
                <li><span class="italic">The Last Revelation</span> (título original <span class="italic">La Última Revelación</span>), por Fernando Gamboa, autor español de mayor venta. (2021-2022)</li>
                <li><span class="italic">Let Me Fly</span> (mismo título en italiano), por A. C. Pontone, autora italiana de mayor venta. (2021)</li>
                <li><span class="italic">Naborí</span> (mismo título en español), letra por Tite Curet Alonso. Traducción de la canción contratada por la compañía de traducción Eriksen Translations para el Smithsonian Latino Center. (2021)</li>
                <li><span class="italic">What's Hidden in the Sky</span> (título original <span class="italic">Bestiario Celestial</span>), por Aina Bestard: libro infantil sobre constelaciones. Traducción contratada por la compañía de traducciones Eriksen Translations. (2021)</li>
                <li><span class="italic">The Fall of the Angel</span> (mismo título en italiano), por Elisa S. Amore, autora italiana de mayor venta. (2021) </li>
                <li><span class="italic">The Secret of the Shooting Star</span> (título original <span class="italic">El disfraz del meteorito</span>), por Cristina Novela, autora española de mayor venta. (2020)</li>
                <li><span class="italic">Oops, Someone's in Trouble</span> (mismo título en italiano), por A. C. Pontone, autora italiana de mayor venta. (2020)</li>
                <li><span class="italic">Flames of Rage</span> (mismo título en italiano), por A. C. Pontone, autora italiana de mayor venta. (2019-2020)</li>
                <li><span class="italic">Flames of Truth</span> (mismo título en italiano), por A. C. Pontone, autora italiana de mayor venta. (2018)</li>
                <li><span class="italic">Rogue Arena</span> (mismo título en italiano), por Elisa S. Amore, autora italiana de mayor venta. (2019-2020) </li>
                <li><span class="italic">The Girl Who Learned to Dream</span> (título original <span class="italic">La chica de los sueños</span>), por Pilar Menen, autora española de mayor venta. (2018)</li>
                <li><span class="italic">A Singular Baptism</span> (título original <span class="italic">Un bautizo singular</span>), por Carlos J. Server, autor español de mayor venta. (2016)</li>
                <li><span class="italic">A Lucky Day</span> (título original <span class="italic">Un día con suerte</span>), por Carlos J. Server, autor español de mayor venta. (2016)</li>
                <li><span class="italic">Operation Matryoshka</span> (título original <span class="italic">Operación matrioska</span>), por Álvaro Botija, autor español de mayor venta. (2015)</li>
                <li><span class="italic">Lord of Minds</span> (título original <span class="italic">La hermandad</span>), por Marcos Chicot, finalista del Premio Planeta 2016 y autor español de mayor venta. (2014)</li>
                <li><span class="italic">“The Crystal Ship,”</span> (título original <span class="italic">“El barco de cristal”</span>), un cuento por Alejandro Meneses. (2014)</li>
              </ul>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Proyectos de edición / corrección de estilo de novelas traducidas al inglés (ya no ofrezco este servicio)
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <ul class="space-y-4">
                <li><span class="italic">Redemption</span> (título original <span class="italic">Redención</span>), por el autor español de mayor venta Fernando Gamboa, traducida por Christy Cox. (2020)</li>
                <li><span class="italic">Dark Tournament</span> (título original Dark Tournament), primera novela en una propuesta serie de tres por la autora italiana de mayor venta Elisa S. Amore, traducida por Leah Janeczko. (2018)</li>
                <li><span class="italic">Expiation: The Whisper of Death</span> (título original <span class="italic">Il canto della morte: Expiation</span>), cuarta novela en una serie de cuatro por la autora italiana de mayor venta Elisa S. Amore, traducida por Leah Janeczko. (2017)</li>
                <li><span class="italic">Brokenhearted: The Power of Darkness</span> (título original <span class="italic">Il potere dell'oscurità: Brokenhearted</span>), tercera novela en una serie de cuatro por la autora italiana de mayor venta Elisa S. Amore, traducida por Leah Janeczko. (2016)</li>
                <li><span class="italic">Unfaithful: The Deception of Night</span> (título original <span class="italic">L'inganno della notte: Unfaithful</span>), segunda novela en una serie de cuatro por la autora italiana de mayor venta Elisa S. Amore, traducida por Leah Janeczko. (2016)</li>
                <li><span class="italic">Touched: The Caress of Fate</span> (título original <span class="italic">Touched: La carezza del destino</span>), primera novela en una serie de cuatro por la autora italiana de mayor venta Elisa Amore, traducida por Leah Janeczko. (2015)</li>
                <li><span class="italic">Killing Pythagoras</span> (título original <span class="italic">El asesinato de Pitágoras</span>) por el autor español de mayor venta Marcos Chicot, traducida por Anamaría Crowe Serrano. (2014)</li>
              </ul>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Testimoniales de Autores
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <testimonial author="Fernando Gamboa" creds="autor de The Last Revelation (título original La última revelación)">
                "Trabajar con Annie Crawford ha sido un placer, hasta el punto de que la traducción
                de La última revelación del español al inglés posiblemente sea solo la primera de
                muchas otras. Su dominio de ambos idiomas y su atención al detalle alaban
                sobradamente su reputación como gran traductora. Espero poder darle las gracias un
                día personalmente ya sea en New Mexico, en Barcelona o en Chiang Mai. Si tiene un
                texto que traducir, no lo dude y contrátela, no se arrepentirá de ello."
              </testimonial>

              <testimonial author="Marcos Chicot" creds="finalista del Premio Planeta 2016 y autor de Killing Pythagoras (título original El asesinato de Pitágoras)">
                “Anne Crawford es una excelente traductora, meticulosa, perspicaz y con un completo 
                dominio de su lengua materna, el inglés, especialmente en el caso del inglés americano. 
                Hizo una corrección de mi novela Killing Pythagoras de gran calidad y detalle, aunando 
                a todo ello una excelente profesionalidad y compromiso -más allá del deber, como dirían 
                en el ejército-, así como un trato humano inmejorable. Estoy deseando volver a trabajar 
                con ella."
              </testimonial>
              
              <testimonial author="Álvaro Botija" creds="autor de Operation Matryoshka (título original Operación matrioska)">
                “Para la traducción de mi primera novela Operación matrioska, buscaba un servicio muy 
                profesional y serio. Tanto Marcos Chicot, finalista del Premio Planeta, como personal 
                de Amazon, me recomendaron a Anne Crawford. Desde el primer momento Anne se ha comportado 
                con una profesionalidad impecable, siempre cercana, atenta y presta a responder rápidamente 
                a cualquier duda o comentario. Ha entregado la traducción en las fechas acordadas y en 
                todo momento me he sentido muy cómodo y seguro con su trabajo. No sólo ha creado una 
                versión en inglés que mejora el original en castellano, sino que gracias a su trabajo he 
                podido corregir errores en la obra original. No puedo más que recomendar sus servicios de 
                traducción y sin duda contaré con ella en cualquier futuro proyecto.”
              </testimonial>
              
              <testimonial author="Anamaría Crowe Serrano" creds="poeta y autora de Femispheres, one columbus leap, y Mirabile Dictu, entre otros">
                “Trabajé con Annie durante varios meses en 2013-2014 en una traducción del español al inglés. 
                Desde el primer momento, fue obvio que su dominio del español era impecable. Sus observaciones 
                lingüísticas siempre son acertadas y perceptivas. Sea en la literatura o en la promoción de un 
                libro, tiene la habilidad innata de encontrar la solución idónea a cualquier problema 
                relacionado con el idioma. Esto quiere decir que puede transformar un texto (en una corrección 
                de estilo), o traducirlo a un inglés de alto nivel que inmediatamente cautiva a los lectores.
                <br><br>
                Además, Annie es una persona profesional y responsable. Es un placer trabajar con ella. Es 
                meticulosa, tolerante ante las sugerencias de otros, y muy consciente de las diferencias 
                culturales. He trabajado con muchos traductores durante muchos años, y honestamente puedo 
                decir que Annie Crawford es una de los mejores.”
              </testimonial>
              
              <testimonial author="Carlos J. Server" creds="autor de A Lucky Day (título original Un día con suerte) y A Singular Baptism (Un bautizo singular)">
                "Elegí a Annie entre varios traductores para mi primer libro Un día con suerte, sin duda no me 
                equivoqué. Trabajar con Annie ha sido un auténtico placer. Es una traductora excelente, meticulosa 
                y concienzuda. Se involucra tanto en su trabajo que hace suya la novela que está traduciendo, y 
                el resultado, no es sólo una traducción, es casi una nueva novela repleta de sutilezas sobre su 
                idioma materno que tan bien conoce y aplica. Para ella, los detalles son importantes y sus 
                sugerencias son la mayoría de las veces un acierto, eso facilita mucho el trabajo de los 
                escritores. Ya le he encargado la traducción de mi segunda novela Un bautizo singular y, por 
                supuesto, pienso encargarle las traducciones de mis próximos trabajos."
              </testimonial>

              <testimonial author="Pilar Menen" creds="autora de The Girl Who Learned to Dream (título original La chica de los sueños)">
                "Escogí a Anne Crawford para la traducción de mi primera novela al inglés, La Chica de los Sueños, 
                porque había leído la excelente traducción de varios de sus trabajos con otros autores españoles 
                y buscaba una traducción muy profesional. Y he de decir que no me ha defraudado. Es una persona 
                muy profesional, conocedora del idioma americano y del español. Ha sido un auténtico placer 
                contar con su inestimable colaboración ya que con sus observaciones ha mejorado el libro con su 
                traducción y me ha facilitado mucho mi trabajo, por su tolerancia en admitir mis sugerencias y 
                por sus acertados comentarios. He trabajado muy a gusto. Su trato es inmejorable. Sin ninguna 
                duda la recomiendo encarecidamente y, por supuesto, no tendré ninguna duda en encargarle futuros 
                proyectos."
              </testimonial>
            </div>
          </div>

          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Precios
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p>
                Para mis servicios de edición/corrección de estilo, cobro US$60.00 por hora. He 
                encontrado que este sistema funciona bien porque el tiempo requerido por cada 
                proyecto puede ser muy diferente aunque el número de palabras sea igual. Si se trata 
                de un proyecto muy largo (una novela, por ejemplo), negociaré con Ud. para llegar a 
                un precio justo.
              </p>
              <p>
                Para servicios de traducción, cobro entre US$.08 y US$.12 por palabra, según la 
                dificultad del texto. También ofrezco "paquetes" que incluyen traducción y edición 
                (corrección de estilo) en un solo precio. Ese precio varía según la dificultad del 
                texto.
              </p>
              <p>
                Ud. se contacte conmigo para pedir una traducción o una corrección de estilo, le 
                pediré que me envíe el primer capítulo de su proyecto. Traduciré o revisaré--según 
                el servicio que le interese--las primeras 750 palabras, para poder darle una 
                estimación del costo sobre la cual podamos negociar para llegar a un precio justo. 
                Así Ud. también puede ver mi trabajo y decidir si le conviene o no. No hay ningún 
                costo por este servicio.
              </p>
            </div>
          </div>

          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Contacto
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p>
                Si cree que le pueden interesar mis servicios, por favor llámeme al (253) 905-9040 
                o mándeme un correo electrónico a la dirección annecrawford829@gmail.com. ¡Muchas gracias!
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";
import Testimonial from "@/components/Testimonial.vue";

export default {
  components: {
    Navbar,
    FooterComponent,
    Testimonial,
  },
};
</script>

    Testimonial