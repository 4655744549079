import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// views without layouts

import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import MyBooks from "@/views/MyBooks.vue";
import Contact from "@/views/Contact.vue";
import Espanol from "@/views/Espanol.vue";
import Pricing from "@/views/Pricing.vue";
import Testimonials from "@/views/Testimonials.vue";
import CopyEditingProofreading from "@/views/ServiceCopyEditingProofreading.vue";
import LineEditing from "@/views/ServiceLineEditing.vue";
import ESLEditing from "@/views/ServiceESLEditing.vue";
import TranslationEditing from "@/views/ServiceTranslationEditing.vue";
import SpanishToEnglish from "@/views/ServiceSpanishToEnglish.vue";
import ItalianToEnglish from "@/views/ServiceItalianToEnglish.vue";

// routes

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/my-books",
    component: MyBooks,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/espanol",
    component: Espanol,
  },
  {
    path: "/pricing",
    component: Pricing,
  },
  {
    path: "/testimonials",
    component: Testimonials,
  },
  {
    path: "/services/copy-editing-proofreading",
    component: CopyEditingProofreading,
  },
  {
    path: "/services/line-editing",
    component: LineEditing,
  },
  {
    path: "/services/esl-editing",
    component: ESLEditing,
  },
  {
    path: "/services/translation-editing",
    component: TranslationEditing,
  },
  {
    path: "/services/spanish-to-english",
    component: SpanishToEnglish,
  },
  {
    path: "/services/italian-to-english",
    component: ItalianToEnglish,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).mount("#app");
