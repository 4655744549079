<template>
  <div>
    <navbar />
    <main class="about-page">
      <!-- <header-component /> -->
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-2/12 px-0 space-y-4"></div>
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                About me
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-2/12 px-0 space-y-4 items-center">
              <img class="mx-auto sm:object-scale-down py-2" :src="annie" alt="...">
            </div>
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                I was born in Cambridge, Massachusetts and lived in New York City with my mother and 
                grandmother, an editor at Harcourt Brace and former literary editor of The Nation, until
                I was four. Not surprisingly, my earliest dream was to be an editor like my grandmother,
                or failing that, a writer or a librarian. I was okay with whatever it was, as long as
                books played a central part.
              </p>
              <p>
                Somewhere along the way life took me on a detour and I ended up majoring in Latin American 
                Studies and Spanish in college and grad school and moving to Puebla, Mexico, where I lived 
                for twenty years (hence the Mexican themes of my first two novels). When my kids went off 
                to school, I began to write, starting out with short stories. My first book-length project 
                was a young-adult novel titled The Ring of Leilani. This was followed by The Curse of the 
                Jade Amulet, completed after I moved back to the U.S. Both books are available on Amazon, 
                as is my vacation memoir, 27 Scoops of Gelato, and my latest young-adult novel, set in the 
                Pacific Northwest and titled The Portal and the Stone.  Find out more about my books 
                <a href="/my-books" class="underline">here</a>.
              </p>
              <p>
                In addition to writing my own books, I’ve done literally hundreds of literary, academic 
                and commercial translations from Spanish and Italian to English during the last twenty-five 
                years. I also taught English and Spanish grammar, composition, conversation, and phonetics 
                at various universities in Mexico and the U.S. for over fifteen years. While living in Mexico, 
                I did freelance editing work for MacMillan Heinemann ELT and Holt, Rinehart and Winston.
              </p>
              <p>
                These days I live the beautiful city of Santa Fe, New Mexico, where I focus exclusively 
                on literary editing and translating. When I’m not working, you can find me hiking, doing 
                yoga, reading, or cooking.
              </p>
            </div>
          </div>

        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
// import HeaderComponent from "@/components/HeaderSmall.vue";
import FooterComponent from "@/components/Footer.vue";

import annie from "@/assets/img/annie-2.jpeg";

export default {
  data() {
    return {
      annie,
    };
  },
  components: {
    Navbar,
    // HeaderComponent,
    FooterComponent,
  },
};
</script>
