<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <!-- <header-component /> -->
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                ESL Editing
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                This service includes all the elements of copy and line editing, but here I also focus on making sure English text written by a non-native speaker flows as smoothly and naturally as anything written by a native speaker. I am fluent in Spanish; not only do I have two master’s degrees in Spanish-related fields, I’ve taught it at the university level, translated it and interpreted it for over twenty years—as well as living in Mexico for most of my adult life. As someone who is bilingual and bicultural, I am able to bring to the editing process a heightened sensitivity to the linguistic differences between other languages and English. 
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
// import HeaderComponent from "@/components/HeaderSmall.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    // HeaderComponent,
    FooterComponent,
  },
};
</script>
