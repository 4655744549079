<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <!-- <header-component /> -->
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Line Editing
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                Line editing is a step up from copy editing, and includes a complete copy edit. In this review process, I consider each line and paragraph of the manuscript and offer corrections and suggestions to improve your writing. In a line edit, I address wordiness, redundancy, overuse of modifiers, verb choices, and sentence and paragraph structure. I’ll note and query you about any inconsistencies I find: for example, if character Mary has blonde hair in Chapter One and red hair in Chapter Nine—and she hasn’t been to the hairdresser in between. I’m also good at catching misplaced paragraphs or glitches in the story timeline. Though not as comprehensive as a developmental edit, this service will substantially improve and streamline your writing as well as guaranteeing it will be free of mechanical errors. 
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-2 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Recent Copy / Line Editing Projects
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-2 sm:px-8 space-y-4">
              <ul class="space-y-4">
                <li><span class="italic">The Magician’s Nine Lives and How He Lost them</span>, by Mel Shakespeare (Summer/Fall 2023)</li>
                <li><span class="italic">The Rise of the King</span>, by Shanae Branham (2021-2022, ongoing)</li>
                <li><span class="italic">Break the Habit! A Step-by-Step Guide to Permanent Weight Loss and a Healthier You</span>, by Mika Fleck (Winter/Spring 2020)</li>
                <li><span class="italic">The Broken Turquoise Laundry Basket</span>, by Marjorie Link (Summer 2018)</li>
                <li><span class="italic">Annabeth's Journey</span>, by Jason Black (Summer 2018)</li>
                <li><span class="italic">Twenty Days of Rain</span>, by Jason Black (Summer 2017)</li>
                <li><span class="italic">Nowhere Home</span>, by Joe Beernink (Summer 2017)</li>
                <li><span class="italic">Fowl Holiday</span>, by Cheri Hooper (Summer 2015)</li>
                <li><span class="italic">Boston's Quest</span>, by Shanae Branham (Spring 2015)</li>
                <li><span class="italic">DiSemblance</span>, by Shanae Branham (Spring 2015)</li>
                <li><span class="italic">“The Sad Circus,”</span> (short story), by Elias Blanco (July 2014)</li>
                <li><span class="italic">Blackpelt</span>, by Jason Black (Elder Road Books, 2014)</li>
                <li><span class="italic">In Other Worlds</span>, (memoir) by John D. Wilson (Westbow Press, 2014)</li>
                <li><span class="italic">Madison Morgan: It’s Not JUST a Dog!</span>, by Pam Torres (Spring 2014)</li>
                <li><span class="italic">Improving the Corporate Office: Why Executives, Managers and Employees Should Use Office Productivity Skills Training (Corporate Office Series – Book One)</span>, Ronald Thomas (March 2014)</li>
                <li><span class="italic">Winterkill</span>, by P. H. Turner (May 2013)</li>
                <li><span class="italic">Pebblehoof</span>, by Jason Black (October 2012)</li>
                <li><span class="italic">Madison Morgan: When Dogs Blog</span>, by Pam Torres (July 2012)</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
// import HeaderComponent from "@/components/HeaderSmall.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    // HeaderComponent,
    FooterComponent,
  },
};
</script>
