<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Contact
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                If you have any questions or would like to request a quote, please contact me by email or phone.
                <ul class="list-inside space-y-2 px-4">
                  <li><i class="fas fa-envelope"></i> annecrawford829@gmail.com</li>
                  <li><i class="fas fa-phone"></i> (253) 905-9040</li>
                </ul>
              </p>
            </div>
          </div>

        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
