<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Pricing
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                For editing, I have a very simple pricing structure that has worked well for me. I charge 
                $60.00 per hour for all my editing services unless the project is unusually lengthy. 
                In that case, I will negotiate with you to arrive at a fair price.
              </p>
              <p>
                Why do I charge the same amount when some services are much more involved than 
                others? The time factor. Copy editing/proofreading goes much more quickly than, 
                say, line editing or ESL editing. It all depends on how much work your manuscript 
                needs. This is why I usually do two edits on the writing sample you send me: a copy 
                edit, and a copy/line edit. It's true it's a bit time-consuming, but I feel it's 
                essential because it allows me to come up with a realistic estimate of how long it 
                will take me to edit your book. At the same time, it enables you to see how I work 
                and decide which of the two types of edits meets your needs. 
              </p>
              <p>
                For literary translation, I charge between $.10 and $.12 per word. The final price 
                depends on the difficulty of the material.  
              </p>
              <p>
                If you’d like to give me a try, don’t forget the introductory offer I made on the 
                home page: For projects over twenty pages, I’ll edit or translate the first three pages 
                (up to 750 words) FREE!! If you decide you’d like me to edit or translate the remainder 
                of your manuscript, great! If you decide not to continue with the project, there’s no 
                obligation.
              </p>
              <p>
                Finally, all my work is 100% guaranteed.
              </p>
              <p>
                Please contact me by phone or email. Thank you and I look forward to working with you!
              </p>
            </div>
          </div>

        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
