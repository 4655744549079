<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <!-- <header-component /> -->
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Spanish > English Literary Translation
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                When you hire me to translate your novel, memoir, or short story to English, 
                you’ll be getting the benefit not only of my extensive experience (20+ years) 
                as a translator, but also my editorial expertise. There are quite a few Spanish 
                to English translators for hire, but not many of them are also editors and 
                published authors in their own right.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Recent Literary Translation Projects
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <ul class="space-y-4">
                <li><span class="italic">The Last Revelation</span> (from Spanish): Book Three of the Ulysses Vidal Adventure Series by Fernando Gamboa, bestselling author in both Spanish and English. (Original Spanish title La última revelación) Spring/summer 2022.</li>
                <li><span class="italic">Un cuento para despertar</span> (from Spanish, English title TBD): Book Two of a trilogy by Spanish author Cristina Novela. (I translated the first book in the trilogy, <span class="italic">Secret of the Shooting Star</span>, in 2020.) Winter 2021-2022.</li>
                <li><span class="italic">Naborí</span> (from Spanish): Lyrics by Tite Curet Alonso. I was hired by Eriksen Translations to translate this song for the Smithsonian Latino Center. Summer 2021.</li>
                <li><span class="italic">What's Hidden in the Sky</span> (from Spanish): A children's picture book about constellations by Aina Bestard. Translated for Eriksen Translations. (Original Spanish title Bestiario celestial) Spring 2021.</li>
                <li><span class="italic">The Secret of the Shooting Star</span> (from Spanish): Book One of a three-book series (as yet unnamed) by Cristina Novela. (Original Spanish title El disfraz del meteorito) Fall 2020.</li>
                <li><span class="italic">The Girl Who Learned to Dream</span>, by bestselling author Pilar Menen Aventin (original Spanish title La chica de los sueños). Fall, 2017/Spring 2018.</li>
                <li><span class="italic">A Singular Baptism</span>, a comedy of intrigue by bestselling author Carlos J. Server (original Spanish title Un bautizo singular). Winter, 2016.</li>
                <li><span class="italic">A Lucky Day</span>, a comedy of intrigue by bestselling author Carlos J. Server (original Spanish title Un día con suerte). Summer, 2016.</li>
                <li><span class="italic">Operation Matryoshka</span>, crime/terrorism thriller by bestselling author Álvaro Botija Ibáñez (original Spanish title Operación Matrioska). Spring, 2015.</li>
                <li><span class="italic">Lord of Minds</span>, crime thriller by bestselling author Marcos Chicot (original Spanish title La Hermandad). Spring, 2014.</li>
                <li>Short story “The Crystal Ship,” by Alejandro Meneses (original Spanish title "El Barco de Cristal").  Colony, Spring, 2014.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
// import HeaderComponent from "@/components/HeaderSmall.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    // HeaderComponent,
    FooterComponent,
  },
};
</script>
