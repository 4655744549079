<template>
  <div class="mb-8">
    <blockquote class="relative p-2 italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote">
      <slot/>
    </blockquote>
    <cite>
      <span class="text-sm not-italic font-bold">{{author}}</span><span class="text-sm not-italic">, {{creds}}.</span>
    </cite>
  </div>
</template>

<script>
export default {
  props: ['author', 'creds'],
};
</script>