<template>
  <div>
    <a
      class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
      :class="{'router-link-active': subIsActive('/services')}"
    >
      Services
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 px-1 space-y-1"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        to="/services/copy-editing-proofreading"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 dropdown"
        active-class="dropdown-active-class"
        exact-active-class="dropdown-exact-active-class"
      >
        Copy Editing / Proofreading
      </router-link>
      <router-link
        to="/services/line-editing"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 dropdown"
        active-class="dropdown-active-class"
        exact-active-class="dropdown-exact-active-class"
      >
        Line Editing
      </router-link>
      <router-link
        to="/services/esl-editing"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 dropdown"
        active-class="dropdown-active-class"
        exact-active-class="dropdown-exact-active-class"
      >
        ESL Editing
      </router-link>
      <router-link
        to="/services/translation-editing"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 dropdown"
        active-class="dropdown-active-class"
        exact-active-class="dropdown-exact-active-class"
      >
        Translation Editing
      </router-link>
      <div class="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
       <span
        class="text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
      >
        Literary Translation
      </span>
      <router-link
        to="/services/spanish-to-english"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 dropdown"
        active-class="dropdown-active-class"
        exact-active-class="dropdown-exact-active-class"
      >
        Spanish to English
      </router-link>
      <router-link
        to="/services/italian-to-english"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 dropdown"
        active-class="dropdown-active-class"
        exact-active-class="dropdown-exact-active-class"
      >
        Italian to English
      </router-link>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },
  },
};
</script>
