<template>
  <div>
    <navbar />
    <main class="mybooks-page">
      <!-- <header-component /> -->
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Testimonials
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-8">
              <p class="mt-0 mb-4">
                I love this page! Here’s where I get to brag. Read what some of my satisfied clients have said about my work:
              </p>

              <testimonial author="Claire Calladine" creds="collaborator on the novel The Magician’s Nine Lives and How He Lost Them, by Mel Shakespeare">
                “Annie Crawford is a GEM. I can't think of anyone who would have had as much love and skills
                to do this project and in the process, elevate the Magician series to a whole new level. I'm
                beyond impressed with her dedication, prowess, and timeliness on this project -- It would have
                taken down a lesser editor for sure, as it is a sprawling ambitious work. There is a fine line
                between a standard edit and one that preserves the genius and integrity of the project, and the
                fact that SHE is the one who was delivered to us is nothing short of a miracle.”
              </testimonial>

              <testimonial author="Fernando Gamboa" creds="bestselling author of Ulysses Vidal adventure series">
                “Working with Annie Crawford has been a pleasure, so much so that the translation of The Last
                Revelation from Spanish to English is only the first of many more collaborations to come. Her
                mastery of English and Spanish and her attention to detail amply attest to her well-deserved
                reputation as an excellent translator. I hope to be able to thank her personally one day,
                whether it’s in New Mexico, Barcelona, or Chiang Mai. If you’ve written a novel and are
                planning to have it translated, don’t hesitate to secure her services—you won’t regret it.”
              </testimonial>

              <testimonial author="Angela Camilla Pontone" creds="bestselling author of The Lost Fae series">
                "Annie Crawford was my salvation. My main language is not English and this has meant that people took advantage of me. First my translator, then the editors I hired. When I finally realized that my book had too many errors, it was too late and Flames of Truth was already being published on Amazon. I found Annie by chance, or rather luckily. She helped me to re-translate my book, to fill in the holes in the plot, and to make the narrative fluent. She didn't try to change my story or my style, but instead she tried to bring out all my qualities as a writer. She is certainly the best editor around, but most of all, she is the most serious, professional and passionate person I’ve ever worked with. She had immense patience with me, comparing my work in Italian with English to make sure that the story had not changed during the translation. So, if you have an idea in mind, but you don't know how to make it shine or how to properly put it on paper, Annie is the person you're looking for. I assure you that you will never find anyone like her."
              </testimonial>
              
              <testimonial author="Marjorie Link" creds="author of The Broken Turquoise Laundry Basket">
                "Anne's skill set as an editor is superb. She is a highly intelligent and meticulous editor.  Her editing process is respectful, efficient, and incredibly supportive.  She was able to maintain my authentic voice throughout the process. I was very impressed with the level of detail and thoroughness of Anne's editing. She gave me helpful suggestions and comprehensive feedback. I would highly recommend Anne's professional editing services to anyone needing assistance."
              </testimonial>
              
              <testimonial author="Elisa S. Amore" creds="bestselling author of the Touched saga">
                "I chose Annie Crawford to be my editor after considering many other candidates. I wanted the best for my books and when I found her, I had no doubt that she was everything I was looking for. Her work on my sample text was simply amazing—it stood out among all the others. The attention she paid to detail was beyond my expectations. Her comments were smart, her suggestions brilliant. My books wouldn’t be the same without her, and the success I have achieved depends on her contributions. One of the things I love about Annie is that she cares about your book. We’re not just talking about finding typos - anyone can catch them. No, she is a genius at finding the little details in the narrative that need to be changed and that you will never find yourself. I trust every one of her suggestions. After working with her for years I have also discovered that she is not only very skilled at doing what she does, she is also a wonderful person who has been much more than an editor to me. If you are searching for quality, don’t think twice: give yourself a gift and hire her right now. Your books NEED her, even if you don't know it yet. Most of all, your books deserve her. Make your books shine."
              </testimonial>
              
              <testimonial author="Marcos Chicot" creds="runner-up for the 2016 Planeta Prize and bestselling author of El Asesinato de Pitágoras and La Hermandad, now available in English as Killing Pythagoras and Lord of Minds">
                “Anne Crawford is an excellent translator, meticulous and perceptive. Her command of English, her mother tongue, and particularly, American English, is impressive. She edited the translation of my novel Killing Pythagoras, paying scrupulous attention to detail and producing a top-quality product. In addition to her skill as a translator, she is extremely conscientious and committed in her professional relationships. As they would say in the armed forces, she goes beyond the call of duty. It was a pleasure to work with her and I look forward to collaborating with her on future projects.”
              </testimonial>
              
              <testimonial author="Joe Beernink" creds="author of Nowhere Wild and Nowhere Home">
                "Anne Crawford came highly recommended to me by another editor, and she did not disappoint. She delivered the edits right on schedule and on budget, and did an exemplary job of copy-editing, ripping out pace-slowing commas by the dozens and catching subtle errors that I had missed in previous edits. She went above and beyond her copy-editing duties and made suggestions for line-edits to improve sentence flow and word choice, and marked sections where I needed to do a little more work to clarify a passage. I will definitely use her services again in the future!"
              </testimonial>
              
              <testimonial author="Carlos J. Server" creds="bestselling author of Un día con suerte, now available in English as A Lucky Day, and Un bautizo singular, now available as A Singular Baptism">
                "I chose Annie from among several potential translators for my first book A Lucky Day (original Spanish title: Un día con suerte). I definitely made the right choice. It has been a real pleasure to work with Annie. She is an excellent translator, meticulous and conscientious. She involves herself so deeply in her work that she makes whatever novel she happens to be translating her own. The result is not just a translation, it’s almost a whole new novel, full of the subtleties of the mother tongue she knows and utilizes so competently. Details are important to her, and most of the suggestions she makes are spot on, which facilitates the writer’s work tremendously. I’ve already secured her services for the translation of my second novel Un bautizo singular, and I will most certainly hire her to translate any future novels I write."
              </testimonial>
              
              <testimonial author="Nicolas Luna" creds="PGCE student at the University of Nottingham, UK and teaching artist at Varee International School, Thailand">
                “Anne Crawford has proofread several of my papers on topics ranging from anthropology to the arts. In all of my documents she has done a thorough and thoughtful job of editing. I especially recommend her services  because not only is she a meticulous grammarian, she is also, and more importantly, a fantastic editor full of wonderfully creative and supportive insights.”
              </testimonial>
              
              <testimonial author="Pam Torres" creds="author of Madison Morgan: When Dogs Blog and Madison Morgan: It’s not JUST a Dog">
                “I have been using Annie’s services for over a year now. I’m a middle-grade writer with several projects that are close to publication. What I love about Annie is her no-nonsense straightforward approach. She’s professional and willing to work with you until you get it right. I trust her because I know she wouldn’t leave any stone unturned when it comes to comprehensive revision and editing. I have also used her services for writing query letters, pitches and synopses. Each time, I not only come away with a polished product but I’ve also learned more that I then apply to my next project. Annie respects the creative sensitivities of the writer and makes suggestions that are logical and maintain the integrity of whatever project I’m working on. I can’t recommend Annie enough.”
              </testimonial>
              
              <testimonial author="P H Turner" creds="author of Winterkill">
                “Anne Crawford generously provided much-needed editing for my first novel, Winterkill. Her thorough line editing and objective comments made Winterkill a better read and me a better writer. Great professional!”
              </testimonial>

              <testimonial author="Anamaría Crowe Serrano" creds="author of Femispheres; one columbus leap; Mirabile Dictu, among others">
                “I worked with Annie for several months in 2013 on a Spanish-English translation. It was obvious from the beginning that her command of Spanish is impeccable. Her linguistic observations are always sharp and accurate. She has an innate ability to find just the right language solution across a range of genres, from literature to marketing. This means she can transform a text (if editing), or translate it into professional, stylistic English that immediately engages the target audience.<br><br>
                Annie is also very reliable and a pleasure to work with. She is conscientious, open to the suggestions of others, and sensitive in dealing with cultural differences. I have worked with many translators over the years and can honestly say Annie Crawford is one of the best.”
              </testimonial>
              
              <testimonial author="Jason Black" creds="freelance editor and “book doctor;” Owner and editor at Plot to Punctuation, LLC; author of Bread for the Pharaoh, Pebblehoof, Blackpelt, Twenty Days of Rain, and Annabeth's Journey">
                “Annie is an excellent copy editor. Her timely work and eye for detail are indispensable for any serious writing project. She is the only copy editor I trust with my manuscripts. I also routinely refer clients to her, knowing she will be able to put that final polish on their writing both faster and better than I could.”
              </testimonial>
              
              <testimonial author="John D. Wilson" creds="author of the memoir In Other Worlds">
                “Anne Crawford is a meticulous editor, sensitive to authorial intent and style, and gives helpful comments and suggestions. Her method not only improved my manuscript, it helped me with guidance and encouragement for future writing. I found her very personable in the way she interacted with me, which gave me a sense of knowing who she was and how she understood me and my writing. Obviously there was a connection because of her cross-cultural experience and knowledge of another language (Spanish). I had noted those credentials on the web, and they were definitely a factor in my decision to ask for her help... Overall, I felt that she really enhanced my book, and I did learn lessons from her which will make me a better writer.”
              </testimonial>
              
              <testimonial author="Álvaro Botija Ibáñez" creds="bestselling author of Operación Matrioska, now available in English as Operation Matryoshka">
                “For the translation of my first novel, Operation Matryoshka (original Spanish title: Operación matrioska), I sought a professional, reliable translator. Author Marcos Chicot, a Planeta Prize finalist, and Amazon exec Rex Czuba, head of Kindle publishing in Spanish, both recommended Anne Crawford. From our very first contact, Anne conducted herself with impeccable professionalism. She was always there when I needed her, responsive and ready to address any comment or question I had. She delivered the completed translation in a timely fashion, and at all times, I felt very confident and comfortable with the quality of her work. Not only did she create an English version of my book that surpassed the Spanish original, but, thanks to her help, I was able to identify and correct errors in the original. I cannot recommend her translation skills highly enough, and I will certainly use her services on any future projects of mine.”
              </testimonial>

              <testimonial author="Cheri Hooper" creds="author of Fowl Holiday">
                "I am so pleased with the editing work that Anne has done on my first novel. Her writing and editing experience show through her work. She has also been very positive and supportive during this process as well as easy to contact when needed. I feel like I will be a better writer because of her comments and suggestions. I also appreciate that when she enjoyed my writing, more specifically my witty sarcasm, she would let me know. I hope to continue my work as an author and complete other novels in the future, in which I will look forward to working with Anne again. I would definitely recommend her services."
              </testimonial>

              <testimonial author="Shanae Branham" creds="author of DiSemblance and Boston's Quest">
                "I want you to know how much I appreciate your word suggestions. . . I appreciated the small language fixes. I know that, combined, each of these fixes will improve the overall story. Thank you for taking the time to not only tell me what was wrong, but also to provide a helpful solution and explanation as to why you saw a need for the changes. I know that doing this was extra work on your part. I want you to know that I value the work you have done for me. You are well worth the money."
              </testimonial>
              
              <testimonial author="Don Kurtz" creds="author of Churchgoers and South of the Big Four">
                "Thanks very, very much for your act of kindness in editing Churchgoers so professionally. You made it a much, much better book."
              </testimonial>
              
              <testimonial author="Pilar Menen" creds="author of The Girl Who Learned to Dream (original title La chica de los sueños)">
                "I chose Anne Crawford to translate my first novel, The Girl Who Learned to Dream (Spanish title La chica de los sueños) to English because I’d read her excellent translations of other Spanish novels. I was looking for a translator who was totally proficient, and I have to say I was not disappointed. Anne is a consummate professional in her field who knows American English inside out, as well as Spanish. It’s been an authentic pleasure to work with her. Her comments and suggestions have made my novel a better book. She made my own work much easier thanks to her perceptive observations and her willingness to consider my suggestions. She’s very easy to work with and I’ve been very happy with her. I can’t recommend her highly enough, and I will certainly use her services again for my future projects."
              </testimonial>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
// import HeaderComponent from "@/components/HeaderSmall.vue";
import FooterComponent from "@/components/Footer.vue";
import Testimonial from "@/components/Testimonial.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    Navbar,
    // HeaderComponent,
    FooterComponent,
    Testimonial,
  },
};
</script>
