<template>
  <div>
    <navbar />
    <main>
      <div class="relative pt-16 flex content-center min-h-screen-75">
        <div class="absolute w-full h-full bg-center bg-cover bg-books">
          <!-- <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span> -->
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap ">
            <div class="px-4 ml-auto mr-auto text-center">
              <div class="pt-48">
                <h1 class="text-black font-semibold text-5xl bg-home-text px-4">
                  Literary Editing and Translating Services
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="pt-28 sm:pt-32 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-24">
            <div class="w-full lg:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="text-4xl font-semibold">In a comma coma? I can help!</h2>
              <p class="mt-2 mb-4 text-blueGray-500">
                    <strong>First impressions are important.</strong> Whether you’re writing a paper, getting your 
                    manuscript ready to submit to an agent, or planning to self-publish, I can help you polish your 
                    writing so you can present a finished project you’ll be proud of. Even if you’re a champ at 
                    spelling and grammar, errors can—and will—slip by you. Remember, spell-check doesn’t distinguish 
                    between homonyms. I can help you be sure your manuscript is free of pesky and embarrassing typos, 
                    grammatical errors, malapropisms, homonyms, run-on sentences, redundancies, monotonous sentence and
                    paragraph structure, etc. I’ve been doing freelance editing for over twenty years.
                  </p>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    <strong>If English isn’t your native language</strong>, my ESL editing and
                     translation services are designed for you. I have over twenty years of experience doing 
                     Spanish-English editing and translation and am fully bilingual and bicultural. I'm also proficient 
                     in reading Italian and have done numerous Italian-English translation projects. I will 
                     revise your paper, essay, or manuscript so that it reads as smoothly in English as if it had been 
                     written by a native English speaker—guaranteed!
                  </p>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    <strong>Introductory offer</strong> for projects over twenty pages: First three pages (up to 750 
                    words) FREE!! If you decide you’d like me to edit or translate the remainder of your manuscript, great! If you 
                    decide not to continue with the project, there’s no obligation.
                  </p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
