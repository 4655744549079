<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <!-- <header-component /> -->
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Copy Editing / Proofreading
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                This is the most basic service I offer. A copy edit focuses on mechanical 
                errors: punctuation, grammar, spelling, run-on sentences, unintentional sentence 
                fragments and typographical errors. It’s a good choice if you’re confident that 
                your writing is as good as it can be and you just want a final run-through to 
                make sure your manuscript or academic paper is perfect before publishing it or 
                handing it in.
              </p>
            </div>
          </div>

        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
// import HeaderComponent from "@/components/HeaderSmall.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    // HeaderComponent,
    FooterComponent,
  },
};
</script>
