<template>
  <footer class="relative bg-footer pt-8 pb-6">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4 mx-auto text-center">
          <h4 class="text-3xl font-semibold text-white">Annie Crawford</h4>
          <h5 class="mt-0 mb-2 text-white">
            Literary Editing and Translating Services
          </h5>
          <ul class="list-inside space-y-2 px-4 text-sm text-white">
            <li><i class="fas fa-envelope"></i> annecrawford829@gmail.com</li>
            <li><i class="fas fa-phone"></i> (253) 905-9040</li>
          </ul>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-6/12 px-4 mx-auto text-center">
          <div class="text-sm text-white font-semibold py-1">
            Copyright © {{ date }} Annie Crawford. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
