<template>
  <div>
    <navbar />
    <main class="mybooks-page">
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-2/12 px-0 space-y-4"></div>
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                My Books
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-2/12 px-0 space-y-2 items-center">
              <a 
                href="http://www.amazon.com/The-Curse-Jade-Amulet-ebook/dp/B0070O8PZG/ref=pd_rhf_dp_p_t_1"
                target="_blank" rel="noopener noreferrer">
                <img class="mx-auto sm:object-scale-down py-2" :src="portal" alt="The Curse of the Jade Amulet">
              </a>
            </div>
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                <span class="italic font-semibold">
                  The Portal and the Stone
                </span>
                (<a 
                  href="https://www.amazon.com/Portal-Stone-Annie-Crawford/dp/B08VVKV2RR"
                  target="_blank" rel="noopener noreferrer"
                  class="underline"
                >available at Amazon</a>)
              </p>
              <p>
                Two families torn apart. One ordinary dog who carries with her a life-changing secret.
              </p>
              <p>
                A year has passed since fifteen-year-old Tessa’s brother Mark vanished without a trace while
                backpacking in Washington. That same day Joey, Tessa’s black lab, came into her life. Several
                states away, Dylan, exiled from his homeland through a cruel twist of fate, struggles to make a life
                for himself while his own missing brother Cormac finds himself in grave danger.
              </p>
              <p>
                Then comes the day when a chance event brings the two families together, setting in motion
                consequences none of them could ever have imagined...
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-2/12 px-0 space-y-2 items-center">
              <a 
                href="http://www.amazon.com/The-Curse-Jade-Amulet-ebook/dp/B0070O8PZG/ref=pd_rhf_dp_p_t_1"
                target="_blank" rel="noopener noreferrer">
                <img class="mx-auto sm:object-scale-down py-2" :src="jadeAmulet" alt="The Curse of the Jade Amulet">
              </a>
            </div>
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                <span class="italic font-semibold">
                  The Curse of the Jade Amulet
                </span>
                (<a 
                  href="http://www.amazon.com/The-Curse-Jade-Amulet-ebook/dp/B0070O8PZG/ref=pd_rhf_dp_p_t_1"
                  target="_blank" rel="noopener noreferrer"
                  class="underline"
                >available at Amazon</a>)
              </p>
              <p>
                Can the mysterious jade amulet transport Nic Porter through time to the era of 
                the classic Mayas? This is just one of the questions the twenty-year-old Mayan 
                history major wants to answer in The Curse of the Jade Amulet. And why does the
                amulet only come alive when Nic, and no one else, touches it? Most importantly, 
                why does his host family at Chichen Itzá, the ancient Mayan ceremonial site 
                where Nic is living and studying, have one of these amulets—when all the others 
                have been found at the bottom of the Sacred Well of Sacrifice along with the 
                remains of the sacrificial victims they were intended for?
              </p>
              <p>
                For Nic these are more than just intriguing questions. For at least a thousand 
                years, the women of his host family have suffered from an unexplained wasting 
                condition which gradually undermines their health and strength, resulting in early 
                death—and now beautiful Itzel, daughter of his host José and Nic’s true love—is 
                showing symptoms as well. José thinks the fact that they have one of the amulets 
                can only mean one thing, that centuries ago an ancestor intended for sacrifice 
                somehow escaped her fate, angering the vindictive Mayan gods and causing them to 
                place a curse for all time on the female line of the family. Nic doesn’t know what 
                to think, but it’s becoming obvious that the amulet itself wants to use him to 
                fulfill its long-dormant destiny…
              </p>
              <p>
                Thus begins an adventure that takes Nic from present-day Yucatán, Mexico to the 
                era of the ancient Mayas and back on a magical quest to discover the secret of 
                the jade amulet and save the life of the girl he loves.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-2/12 px-0 space-y-4 items-center">
              <a 
                href="http://www.amazon.com/The-Ring-of-Leilani-ebook/dp/B007OXZXXA/ref=sr_1_5?s=books&ie=UTF8&qid=1333130733&sr=1-5"
                target="_blank" rel="noopener noreferrer">
                <img class="mx-auto sm:object-scale-down py-2" :src="ringOfLeilani" alt="The Ring of Leilani">
              </a>
            </div>
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                <span class="italic font-semibold">The Ring of Leilani</span>
                (<a 
                  href="http://www.amazon.com/The-Ring-of-Leilani-ebook/dp/B007OXZXXA/ref=sr_1_5?s=books&ie=UTF8&qid=1333130733&sr=1-5"
                  target="_blank" rel="noopener noreferrer"
                  class="underline"
                >available at Amazon</a>)
              </p>
              <p>
                The last thing fifteen-year-old Julia Dickinson expects while vacationing with her 
                parents in Cancun is to find an ancient crown in an underwater cave, try it on... 
                and be transformed into a mermaid. And not just any mermaid, but a direct descendant 
                of the long-lost Princess Leilani and the heir to Leilani’s throne. The idea of being a 
                queen is exciting, and the members of the mer-clan are overjoyed to have a leader once 
                more... but Julia’s not at all sure she’s ready to give up her life as a human.
              </p>
              <p>
                Julia’s fate hinges on a missing magic ring. Before she can become queen, she must find 
                the ring, lost for a hundred fifty years, since the crown is useless without it. And 
                when she learns that the combined power of crown and ring can make her human again, 
                she’s even more motivated to search for it.
              </p>
              <p>
                Accompanied by one of the merclan elders, Julia embarks on a perilous quest to find 
                the ring. Along the way she blunders into a fishing net and meets Chac, a 
                seventeen-year-old Mayan boy enamored of merfolk who promises to help her in her 
                search. But if and when Julia does find the ring, can she also find happiness as 
                a mermaid queen?
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-24">
            <div class="w-full sm:w-2/12 px-0 space-y-4 items-center">
              <a 
                href="http://www.amazon.com/27-Scoops-Gelato-Annie-Crawford/dp/1477583823"
                 target="_blank" rel="noopener noreferrer"
              >
                <img class="mx-auto sm:object-scale-down py-2" :src="gelato" alt="27 Scoops of Gelato">
              </a>
            </div>
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                <span class="italic font-semibold">27 Scoops of Gelato</span>
                (<a 
                  href="http://www.amazon.com/27-Scoops-Gelato-Annie-Crawford/dp/1477583823"
                  class="underline"
                   target="_blank" rel="noopener noreferrer"
                >available at Amazon</a>)
              </p>
              <p>
                How many scoops of gelato can two people consume on a three-week trip through central 
                Italy? What happens to people who don’t slam their glasses on the table after a shot 
                of limoncello? Are gladiatorial combats still held under cloak of darkness in the old 
                Roman town of Pisa? Discover the answers to these questions and much more in this funny, 
                sometimes irreverent, but always entertaining romp through the cities and towns of Tuscany.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";

import jadeAmulet from "@/assets/img/jade-amulet.jpg";
import ringOfLeilani from "@/assets/img/ring-of-leilani.jpg";
import gelato from "@/assets/img/27-scoops-of-gelato.jpg";
import portal from "@/assets/img/portal.jpg";

export default {
  data() {
    return {
      jadeAmulet,
      ringOfLeilani,
      gelato,
      portal,
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
