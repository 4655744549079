<template>
  <nav class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-navbar shadow">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
        <a class="text-white text-xl font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase link-home" href="/">
          <!-- <a class="" href="#"> -->
            Annie Crawford
          <!-- </a> -->
        </a>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto space-x-2">
          <li></li>
          <li class="flex items-center">
            <router-link
              to="/"
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold">
              Home
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link
              to="/about"
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold">
              About
            </router-link>
          </li>
          <li class="flex items-center">
            <index-dropdown />
          </li>
          <li class="flex items-center">
            <router-link
              to="/testimonials"
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold">
              Testimonials
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link
              to="/pricing"
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold">
              Pricing
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link
              to="/my-books"
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold">
              My Books
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link
              to="/contact"
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold">
              Contact
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link
              to="/espanol"
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold">
              Español
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import IndexDropdown from "@/components/NavbarDropdown.vue";

export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  components: {
    IndexDropdown,
  },
};
</script>
