<template>
  <div class="flex flex-col h-screen justify-between">
    <navbar />
    <main class="about-page mb-auto">
      <!-- <header-component /> -->
      <section class="pt-28 sm:pt-36 pb-8">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h1 class="font-semibold text-4xl">
                Translation Editing
              </h1>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <p class="mt-0">
                This is a service I no longer offer. I include this note here simply to explain why I made the
                decision to remove it from my webpage. Though I have done numerous projects of translation
                editing (see below) I eventually came to realize that it was not worth my while, since it was
                actually more difficult than translating a book from scratch. If you have come to my website
                looking for a translation edit because you are unhappy with the results of your translation, I
                encourage you to consider having me re-translate your book. The price is more or less equivalent
                to the cost of an edit since it actually involves less work.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-4">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <h2 class="font-semibold text-2xl">
                Recent Translation Editing Projects
              </h2>
              <hr/>
            </div>
          </div>
          <div class="flex flex-wrap justify-center mb-12">
            <div class="w-full sm:w-8/12 px-0 sm:px-8 space-y-4">
              <ul class="space-y-4">
                <li>
                  Summer/Fall 2020: I edited the Spanish-to-English translation of 
                  <span class="italic">Redemption</span> by bestselling
                  Spanish author Fernando Gamboa (translated by Christy Cox).
                </li>
                <li>
                  Winter/Spring 2019: I edited the Italian-to-English translation of 
                  <span class="italic">Flames of Fear</span>, Book Two of the three-book 
                  paranormal reverse harem romance series, <span class="italic">The Lost Fae</span>, 
                  by bestselling Italian author Angela Camilla Pontone.
                </li>
                <li>
                  Winter 2019: I edited the Italian-to-English translation of 
                  <span class="italic">Flames of Ice</span>, the prequel to the three-book 
                  paranormal reverse harem romance series, <span class="italic">The Lost Fae</span>, 
                  by bestselling Italian author Angela Camilla Pontone.
                </li>
                <li>
                  Spring 2018: I edited the Italian-to-English translation of 
                  <span class="italic">Dark Tournament</span>, the first novel in a 
                  new three-book paranormal romance series by Elisa S. Amore, bestselling 
                  Italian author (in both Italian and English). Her first YA saga, 
                  <span class="italic">Touched</span>, which included the novels 
                  <span class="italic">The Caress of Fate</span>; 
                  <span class="italic">Unfaithful: The Deception of Night</span>; 
                  <span class="italic">Brokenhearted: The Power of Darkness</span>; and 
                  <span class="italic">Expiation: the Whisper of Death</span> 
                  rose to the number one spots in their category shortly after publication, 
                  and have been Amazon.com bestsellers ever since.
                </li>
                <li>
                  Winter 2017: I edited the Italian-to-English translation of 
                  <span class="italic">Expiation: the Whisper of Death</span>, the 
                  fourth and final novel in a four-book paranormal romance series by 
                  Elisa S. Amore, bestselling Italian author (in both Italian and English). 
                  The first three books in the series, 
                  <span class="italic">Touched: The Caress of Fate</span>; 
                  <span class="italic">Unfaithful: The Deception of Night</span>; 
                  and <span class="italic">Brokenhearted: The Power of Darkness</span> 
                  rose to the number one spots in their category shortly after 
                  publication, and have been Amazon.com bestsellers ever since.
                </li>
                <li>
                  Summer 2016: I edited the Italian-to-English translation of 
                  <span class="italic">Brokenhearted</span>, the third in a four-book paranormal 
                  romance series by Elisa S. Amore, bestselling Italian author (in both Italian 
                  and English). The first two books in the series, 
                  <span class="italic">Touched: The Caress of Fate</span>, and 
                  <span class="italic">Unfaithful: The Deception of Night</span>, rose to 
                  the number one spots in their category shortly after publication, 
                  and are now officially Amazon.com bestsellers.
                </li>
                <li>
                  Spring 2016: I edited the Italian-to-English translation of 
                  <span class="italic">Unfaithful: The Deception of Night</span>, the second 
                  in a four-book paranormal romance series by Elisa S. Amore, bestselling 
                  Italian author. The first book in the series, <span class="italic">Touched: The Caress of Fate</span>, 
                  rose to the number one spot in its category shortly after 
                  publication, and is now officially an Amazon.com bestseller.
                </li>
                <li>
                  Winter 2015: I edited the Italian-to-English translation of 
                  <span class="italic">Touched: The Caress of Fate</span>, the first 
                  in a four-book paranormal romance series by Elisa S. Amore, 
                  bestselling Italian author.
                </li>
                <li>
                  Fall 2013: I edited the English translation of <span class="italic">Killing Pythagoras</span>, 
                  a historical crime thriller written by Spanish author Marcos Chicot. 
                  The Spanish version of this novel (<span class="italic">El Asesinato de Pitágoras</span>) was the top-selling 
                  Spanish ebook in the world for five consecutive months. Available on Amazon.com 
                  in paper and digital formats.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
// import HeaderComponent from "@/components/HeaderSmall.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    // HeaderComponent,
    FooterComponent,
  },
};
</script>
